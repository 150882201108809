import Head from "next/head";
import { DefaultSeo } from "next-seo";
function TTBBMetaHead() {
  return <>
      <DefaultSeo title="티티비비 TTBB" description="KB국민카드 X 트립비토즈 자유여행 플랫폼" openGraph={{
      type: "website",
      url: "https://www.kbttbb.com/",
      title: "KBTTBB",
      description: "Trip&Travel,Better&Beyond",
      images: [{
        url: "https://asset.tripbtoz.com/images/ttbb/meta_og_image.png"
      }]
    }} twitter={{
      cardType: "summary",
      site: "@KBTTBB"
    }} />
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta name="apple-mobile-web-app-capable" content="no" />
        <meta name="keywords" content="호텔, 리조트, 숙박, 여행, 해외여행, 국내여행, 호텔할인, 호텔예약, 주말여행, 추천호텔, 특가호텔, 호캉스, 티티비비, TTBB" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="format-detection" content="telephone=no" />
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-2048-2732.jpg" media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-2732-2048.jpg" media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-1668-2388.jpg" media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-2388-1668.jpg" media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-1536-2048.jpg" media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-2048-1536.jpg" media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-1668-2224.jpg" media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-2224-1668.jpg" media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-1620-2160.jpg" media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-2160-1620.jpg" media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-1284-2778.jpg" media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-2778-1284.jpg" media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-1170-2532.jpg" media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-2532-1170.jpg" media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-1125-2436.jpg" media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-2436-1125.jpg" media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-1242-2688.jpg" media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-2688-1242.jpg" media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-828-1792.jpg" media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-1792-828.jpg" media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-1242-2208.jpg" media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-2208-1242.jpg" media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-750-1334.jpg" media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-1334-750.jpg" media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-640-1136.jpg" media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/ttbb/apple-splash/apple-splash-1136-640.jpg" media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"></link>
        <link rel="apple-touch-icon" href="/weblogo/ttbb/apple-icon/apple-icon-180x180.png" sizes="any"></link>
        <link rel="icon" type="image/svg+xml" href="/weblogo/ttbb/favicon/favicon.svg" sizes="any"></link>
        <link rel="icon" type="image/png" href="/weblogo/ttbb/favicon/favicon-16x16.png" sizes="16x16"></link>
        <link rel="icon" type="image/png" href="/weblogo/ttbb/favicon/favicon-32x32.png" sizes="32x32"></link>
        <link rel="icon" type="image/png" href="/weblogo/ttbb/favicon/favicon-96x96.png" sizes="96x96"></link>
        <link rel="icon" type="image/png" href="/weblogo/ttbb/favicon/favicon-128x128.png" sizes="128x128"></link>
        <link rel="icon" type="image/png" href="/weblogo/ttbb/favicon/favicon-192x192.png" sizes="192x192"></link>
        <link rel="icon" type="image/png" href="/weblogo/ttbb/favicon/favicon-196x196.png" sizes="196x196"></link>
      </Head>
    </>;
}
export default TTBBMetaHead;